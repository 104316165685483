/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import CopywritingChecklistForm from "../../componentsV2/CheckListForms/CopywritingChecklist"

const PageContainer = styled.div`
  height: auto;
`

const CopywritingFormPage = () => (
  <PageContainer>
    <Helmet>
      <meta name="robots" content="noindex" />
      <title>AirMason | Copywriting Checklist Form</title>
      <meta
        name="description"
        content="AirMason is an online tool that makes it easy for companies to get new hires up to speed quickly with an amazing employee handbook."
      />
    </Helmet>
    <CopywritingChecklistForm />
  </PageContainer>
)

export default CopywritingFormPage
