import React from "react"
import styled, { StyledComponent } from "@emotion/styled"
import tw from "tailwind.macro"

const Label: StyledComponent<any, any, any> = styled.label`
  ${tw`
    flex items-center select-none cursor-pointer font-light text-black-4 text-sm
  `}

  input {
    ${tw`
      absolute cursor-pointer opacity-0
    `}
  }

  .checkmark {
    height: 20px;
    width: 20px;
    ${tw`
      flex items-center justify-center border border-gray-1 rounded-full mr-3
    `}
  }

  .checkmark:after {
    content: "";
    height: 12px;
    width: 12px;
    display: none;
    background-color: rgb(71, 166, 248);
    ${tw`
     rounded-full
    `}
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  ${({ css }) => css}
`

const RadioButton = ({
  caption,
  className,
  captionClassName,
  onChange,
  ...props
}: any) => (
  <Label disabled={props.disabled} className={`${className || ""}`}>
    <input
      type="radio"
      {...props}
      onChange={(e: any) => {
        onChange && onChange(e.target.value, e)
      }}
    />
    <span className="checkmark" />
    <span
      className={`caption opacity-70 font-medium ${captionClassName || ""}`}
    >
      {caption}
    </span>
  </Label>
)

export default RadioButton
