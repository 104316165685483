import React from "react"
import styled, { StyledComponent } from "@emotion/styled"
import tw from "tailwind.macro"

const Wrapper: StyledComponent<any, any, any> & any = styled.div`
  .input:checked + .toggle {
    ${tw` text-white`}
    background-color: #41898C;
    border-color: #41898C;
    &:hover {
      border-color: #3d4353;
    }

    .slider {
      .dot {
        left: calc(100% - 23px);
        &:hover {
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.63);
        }
      }

      .on {
        margin-left: 0;
        opacity: 1;
      }

      .off {
        opacity: 0;
      }
    }
  }
`

const Label: StyledComponent<any, any, any> = styled.label`
  ${tw`flex items-center justify-center whitespace-no-wrap`}
  ${({ css }) => css}
`
const Input: StyledComponent<any, any, any> = styled.input`
  ${tw`opacity-0 w-0 h-0 text-sm`}
  ${({ css }) => css}
`

const Toggles: StyledComponent<any, any, any> = styled.div`
  ${tw`relative bg-gray-22 font-avenirHeavy text-13/16`}
  min-width: 64px;
  max-width: 92px;
  width: ${props => (props.width ? `${props.width}px` : "64px")};
  height: 26px;
  border-radius: 13px;
  overflow: hidden;
  line-height: 18px;
  border: 1px solid #c5c7cc;
  color: rgba(61, 67, 83, 0.4);
  transition: all 0.3s;
  &:hover {
    border-color: #3d4353;
  }
  ${({ css }) => css};
`
const Slider: StyledComponent<any, any, any> = styled.span`
  ${tw`flex items-center cursor-pointer h-full px-2 leading-none`}
  .dot {
    ${tw`bg-white rounded-full absolute`}
    left: 3px;
    transition: all 0.3s ease-in-out;
    height: 20px;
    width: 20px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.27);
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .on {
    opacity: 0;
    margin-left: -100%;
  }
  .off {
    ${tw`ml-auto`}
  }

  ${({ css }) => css}
`

const Toggle = ({ className, width, ...props }: any) => {
  const label = props.label || { on: "On", off: "Off" }

  return (
    <Wrapper className={`${className || ""} toggle-wrapper`}>
      <Label>
        <Input
          {...props}
          className="input"
          type="checkbox"
          onChange={(e: any) => {
            props.onChange && props.onChange(e.target.checked, e)
          }}
        />
        <Toggles className="toggle" width={width}>
          <Slider className="slider">
            <span className="on">{label.on}</span>
            <span className="dot" />
            <span className="off ">{label.off}</span>
          </Slider>
        </Toggles>
      </Label>
    </Wrapper>
  )
}

export default Toggle
