import React from "react";
import { Dot, GridRadioDiv, SubTitle, SubmitButton, TextArea, Title } from "./styles";
import Checkbox from "src/componentsV2/ui/Checkbox";
import Toggle from "./Toggle";
import backArrow from "src/images/back_arrow.svg";

export const companyCultures = [
    "Innovative",
    "Collaborative",
    "Corporate",
    "Supportive",
    "Dynamic",
    "Inclusive",
    "Flexible",
    "Driven",
    "Transparent",
    "Empowering",
    "Agile",
    "Friendly",
    "Respectful",
    "Customer-focused",
    "Sustainable",
    "Accountable",
    "Fun",
    "Creative",
    "Passionate",
    "Professional",
    "Team-oriented",
    "Work-life balance",
    "Open-door policy",
    "Continuous learning",
    "Employee-centric",
    "Customer-centric",
    "Performance-driven",
    "Growth mindset",
    "Community involvement",
    "Diversity and inclusion",
    "Innovation-driven",
    "High-performance",
    "Collaborative environment",
    "Transparent communication",
    "Employee development",
    "Recognition and rewards",
    "Result-oriented",
    "Safety-first",
    "Sustainability-focused",
    "Entrepreneurial spirit",
    "Other… (add some more)"
];

export const culturePageData = [
    {
        name: "timelinePage",
        title: "History or Timeline page",
        description: "Enter any bullet points you want to include on the page, or type N/A if you'd prefer a placeholder until you can provide more information",
    },
    {
        name: "byNumberPage",
        title: "Your company, By The Numbers page",
        description: "Key metrics and accomplishments presented in an infographic style or carousel format",
    },
    {
        name: "milestonePage",
        title: "Company Milestones/Achievements/Awards page",
        description: "List of your company’s accomplishments; either use existing photo assets or design can optimize with vector images.",
    },
    {
        name: "digitalToolkitPage",
        title: "Your Digital Toolkit page",
        description: "The digital software your employees use at work on a daily basis. Looks great with logos. Linkable capabilities.",
    },
    {
        name: "glossaryOfTermPage",
        title: "Glossary of Terms page",
        description: "The digital software your employees use at work on a daily basis. Looks great with logos. Linkable capabilities.",
    },
    {
        name: "culturalBlurbPage",
        title: "Cultural Blurbs page",
        description: "100-150 words on the topic or theme of your choice (e.g. transparency, autonomy, sustainability, teamwork, giving back",
    },
    {
        name: "whatToExpectPage",
        title: "What to Expect Here page",
        description: "Things employees should look forward to as a result of working at your company.",
    },
    {
        name: "whatCanExpectPage",
        title: "What You Can Expect From Us page",
        description: "What employees can expect when working at your company.",
    },
    {
        name: "careerAdvancementPage",
        title: "Career Advancement page",
        description: "How your company helps to advance the careers of its employees.",
    },
    {
        name: "employeeRecognitionPage",
        title: "Employee Recognition page",
        description: "How your company rewards hard work and loyalty.",
    },
    {
        name: "testimonialPage",
        title: "Testimonials page",
        description: "Quotes from your employees on what it’s like to work at your company.",
    }
]

interface FormState {
    includeCulturePages: boolean
    otherCulturePages: boolean
    cultureAdjectives: number[]
    culturePages: number[]
}

interface FormProps {
    onBack: Function
    visible: boolean
}

const Form3: React.FC<FormProps> = props => {
    const [state, setState] = React.useState<FormState>({
        includeCulturePages: false,
        otherCulturePages: false,
        cultureAdjectives: [],
        culturePages: []
    });

    function onColumnSelect(checked: boolean, index: number): void {
        let adjectives: number[] = []

        if (checked) {
            if (state.cultureAdjectives.length >= 10) {
                adjectives = [
                    ...state.cultureAdjectives.slice(1),
                    index
                ];
            } else {
                adjectives = [...state.cultureAdjectives, index];
            }
        } else {
            adjectives = state.cultureAdjectives.filter(item => item !== index);
        }
        setState({ ...state, cultureAdjectives: adjectives.sort((a, b) => a - b) });
    }

    return (
        <div className={props.visible ? "visible" : "hidden"}>
            <Title>Company culture</Title>
            <SubTitle>Please select up to 10 adjectives and terms below that best describe your company’s work culture:</SubTitle>

            <GridRadioDiv marginTop={16}>
                {companyCultures.map((culture, index) => {
                    return (
                        <Checkbox
                            name="cultureAdjectives"
                            key={index}
                            label={culture}
                            className="mb-2"
                            isBlueColor={true}
                            labelClassName={state.cultureAdjectives.includes(index) && "font-bold"}
                            value={culture}
                            checked={state.cultureAdjectives.includes(index)}
                            onChange={(checked: boolean) => onColumnSelect(checked, index)}
                        />
                    )
                })}
            </GridRadioDiv>

            {state.cultureAdjectives.includes(companyCultures.length - 1) &&
                <TextArea
                    name="additionalCultureAdjectives"
                    placeholder="Enter text"
                    className="h-25 mt-4" />
            }

            <SubTitle marginTop={44}>What should employees feel most proud about working at your company?</SubTitle>
            <TextArea
                name="employeeMostProud"
                placeholder="Add any additional terms or adjectives you think are important.."
                className="h-25 mt-4" />

            <Title marginTop={32}>Culture page options</Title>
            <div className="flex items-center justify-between">
                <SubTitle marginTop={16}>Would you like us to include cultural pages in your handbook?</SubTitle>
                <Toggle
                    label={{ on: "Yes", off: "No" }}
                    defaultChecked={false}
                    name="includeCulturePages"
                    value={state.includeCulturePages}
                    onChange={(checked: boolean) => setState({ ...state, includeCulturePages: checked })}
                />
            </div>

            {state.includeCulturePages &&
                <div className="mt-10">
                    <SubTitle>Please select which culture page(s):</SubTitle>

                    {culturePageData.map((culture, index) => {
                        return (
                            <div className="flex mt-6" key={index}>
                                <Dot />
                                <div className="ml-4 flex-1">
                                    <div className="flex justify-between items-center">
                                        <SubTitle marginTop={1} bold={true}>{culture.title}</SubTitle>
                                        <Toggle
                                            label={{ on: "Yes", off: "No" }}
                                            defaultChecked={false}
                                            name={`${culture.name}`}
                                            value={state.culturePages.includes(index)}
                                            onChange={(checked: boolean) => {
                                                let pages: number[] = []

                                                if (checked) {
                                                    pages = [...state.culturePages, index];
                                                } else {
                                                    pages = state.culturePages.filter(item => item !== index);
                                                }
                                                setState({ ...state, culturePages: pages });
                                            }}
                                        />
                                    </div>
                                    <p className="mt-4 mr-14 text-sm">{culture.description}</p>
                                    {state.culturePages.includes(index) &&
                                        <TextArea
                                            name={`${culture.name}Note`}
                                            fontStyle="normal"
                                            placeholder="Please describe in more detail..."
                                            className="h-25 mt-4" />
                                    }
                                </div>
                            </div>
                        )
                    })}


                    <div className="flex items-center justify-between mt-6">
                        <SubTitle>Would you like to include any other culture pages?</SubTitle>
                        <Toggle
                            label={{ on: "Yes", off: "No" }}
                            defaultChecked={false}
                            name="otherCulturePages"
                            onChange={(checked: boolean) => setState({ ...state, otherCulturePages: checked })}
                        />
                    </div>
                    <p className="mt-4 text-sm">We’re open to your ideas. Have a vision for a culture page that isn’t listed above? Tell us about it.</p>
                    {state.otherCulturePages &&
                        <TextArea
                            name="otherCulturePages"
                            placeholder="Please describe in more detail..."
                            className="h-25 mt-4" />
                    }
                </div>
            }

            <SubTitle marginTop={30}>Any final thoughts or things we should know?</SubTitle>
            <TextArea
                name="finalThoughts"
                placeholder="(optional)"
                className="h-25 mt-4" />

            <div className="flex justify-between mt-10">
                <div
                    className="flex items-center cursor-pointer"
                    onClick={() => props.onBack()}
                >
                    <img src={backArrow} alt="back" />
                    <p className="text-black-1 text-xl ml-4">Back</p>
                </div>
                <p className="text-black-1 text-xl ml-4 mt-1.5">3 of 3</p>
                <SubmitButton type="submit">Submit</SubmitButton>
            </div>
        </div>
    )
}

export default Form3;