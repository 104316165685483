import styled from "styled-components";
import tw from "tailwind.macro";

export const StepBarTitle = styled.div<{ active?: boolean }>`
    font-weight: ${props => props.active ? '700' : '400'};
    font-size: 16px;
    margin-top: 14px;
`

export const StepBarCircle = styled.div<{ active?: boolean, completed?: boolean }>`
  width: 40px;
  height: 40px;
  border-width: ${props => props.active && !props.completed ? '2px' : !props.active && !props.completed ? '1px' : '0px'};
  border-color: ${props => props.active ? '#3E3E41' : '#979797'};
  border-radius: 24px;
  background-image: ${props => props.completed ? `url(${require("src/images/icons/check_icon.svg")})` : 'none'}
`

export const Title = styled.h1<{ marginTop?: number }>`
  ${tw`text-2xl text-black-14 font-avenirHeavy`}
  margin-top: ${props => props.marginTop ? `${props.marginTop}px` : '0'};
`

export const SubTitle = styled.div<{ marginTop?: number, bold?: boolean }>`
  ${tw`text-xl text-black-14`}
  margin-top: ${props => props.marginTop ? `${props.marginTop}px` : '16px'};
  font-weight: ${props => props.bold ? '700' : '400'};
`

export const Input = styled.input`
  ${tw`border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 w-full text-15px py-10px px-15px mr-3 mt-4 focus:outline-none`}
`

export const RadioInput = styled.input`
  ${tw`w-5 h-5 text-gray-1 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2`}
`

export const GridRadioDiv = styled.div<{ columns?: number, marginTop?: number }>`
  ${tw`mt-4`}
  display: grid;
  grid-template-columns: ${props => props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(3, 1fr)'};
  row-gap: 16px;
  column-gap: 8px;
  margin-top: ${props => props.marginTop ? `${props.marginTop}px` : '16px'};

  .checkmark:after {
    background-color: #414143 !important;
  }
`

export const TextArea = styled.textarea<{ height?: number, fontStyle?: string }>`
  ${tw`
    font-body
    appearance-none
    block
    antialiased
    text-base
    border
    border-gray-16
    rounded
    w-full
    px-3
    py-3
    font-light
    text-black
    leading-tight
    resize-none
    min-h-32
    focus:outline-none
    focus:shadow
    rounded-md 
    shadow-sm 
    bg-white
`}
  height: ${props => props.height ? `${props.height}px` : '76px'};

  &::placeholder {
    opacity: 1;
    text-color: #3D4353;
    font-style: ${props => props.fontStyle ? `${props.fontStyle}` : 'italic'};
    font-size: 14px;
  }
`

export const SubmitButton = styled.button`
  ${tw`py-2 px-4 bg-blue-1 rounded-full text-white text-15px`}
  width: 120px;
`

export const ExampleLink = styled.a`
  ${tw`text-blue-1 no-underline text-sm mt-3 ml-8 font-bold`}
`

export const Dot = styled.div`
  background-color: #D8D8D8;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin-top: 8px;
`