import React, { useState } from "react"
import { RouteComponentProps } from "@reach/router"
import Header from "./Header"
import About from "./About"
import CopywritingChecklist from "./Checklist/Copywriting"

const PoliciesFinder: React.FC<RouteComponentProps<any>> = ({}) => {
  const [succeeded, setSucceeded] = useState(false);
  return (
    <div className="flex flex-col bg-white mx-auto font-sans">
      <Header bgColor="bg-gray-2" />
      <About
        title={succeeded ? "All done!" : "Copywriting questionnaire for Welcome Manual 2024"}
        description={succeeded
        ? ["Our team has received your completed form and will be in touch soon."]
        : ["The purpose of this questionnaire is to ensure our creative team is able to write a stunning handbook with your brand's tone of voice front and centre.",
           ""
        ]}
        showBackButton={succeeded}
      />
      {!succeeded && <CopywritingChecklist setSucceeded={setSucceeded} />}
    </div>
  )
}

export default PoliciesFinder
