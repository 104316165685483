import React, { useState } from "react";
import { Dot, ExampleLink, GridRadioDiv, SubTitle, SubmitButton, TextArea, Title } from "./styles";
import RadioButton from "src/componentsV2/ui/RadioButton";
import backArrow from "src/images/back_arrow.svg";
import Toggle from "./Toggle";

const handbookAudiences = [
    "Existing employees",
    "New hires",
    "Both",
];

const handbookTones = [
    {
        name: "Short and to the point",
        url: "https://books.airmason.com/airmasonsamples/handbook_tone_examples#short"
    },
    {
        name: "Casual and conversational",
        url: "https://books.airmason.com/airmasonsamples/handbook_tone_examples#casualconversational"
    },
    {
        name: "Formal",
        url: "https://books.airmason.com/airmasonsamples/handbook_tone_examples#formal"
    },
    {
        name: "Corporate",
        url: "https://books.airmason.com/airmasonsamples/handbook_tone_examples#corporate"
    },
    {
        name: "Other (a combination of these)",
    }
];

const toneStyles = [
    "No humour",
    "Humor when warranted",
];

const technicalRefs = [
    {
        name: "Section introductions/Overviews",
        url: "https://books.airmason.com/airmasonsamples/section_intros_vs_no_section_intros#introductions"
    },
    {
        name: "Go directly into the policy",
        url: "https://books.airmason.com/airmasonsamples/section_intros_vs_no_section_intros#direct"
    }
];

const technicalBlurbs = [
    {
        name: "Short / simple",
        url: "https://books.airmason.com/airmasonsamples/shortvslongblurbs#short"
    },
    {
        name: "Long / detailed",
        url: "https://books.airmason.com/airmasonsamples/shortvslongblurbs#long"
    }
];

const technicalSummaries = [
    {
        name: "Short / simple",
        url: "https://books.airmason.com/airmasonsamples/evergreen_vs_detailed_blurbs#evergreen"
    },
    {
        name: "Long / detailed",
        url: "https://books.airmason.com/airmasonsamples/evergreen_vs_detailed_blurbs#detailed"
    }
];

const policySubsetLists = [
    {
        name: "As a group",
        url: "https://books.airmason.com/airmasonsamples/longer_policy_breakdown_formats#grouped"
    },
    {
        name: "Separately",
        url: "https://books.airmason.com/airmasonsamples/longer_policy_breakdown_formats#separated"
    }
];

const policyLinks = [
    {
        name: "External linking",
        url: "https://editor.airmason.com/books/linkouts?eid=MzU3&preview=true"
    },
    {
        name: "Internal expansions",
        url: "https://editor.airmason.com/books/example?eid=NjM5&preview=true"
    }
];

const reOrderPolicies = [
    "Yes, AirMason can adjust ordering",
    "No, keep our current order"
];

const welcomeMessProvidedBy = [
    "Your CEO",
    "AirMason to create one"
];

interface FormState {
    handbookAudienceIndex: number
    handbookToneIndex: number
    toneStyleIndex: number
    technicalPrefIndex: number
    technicalBlurbsIndex: number
    technicalSummariesIndex: number
    policyReorderIndex: number
    policySubsetListIndex: number
    policyLinksIndex: number
    includeWelcomeMess: boolean
    welcomeMessProvidedByIndex: number
}

interface FormProps {
    onBack: Function
    onNext: Function
    visible: boolean
}

const Form2: React.FC<FormProps> = props => {
    const [state, setState] = useState<FormState>({
        handbookAudienceIndex: 0,
        handbookToneIndex: 0,
        toneStyleIndex: 0,
        technicalPrefIndex: 0,
        technicalBlurbsIndex: 0,
        technicalSummariesIndex: 0,
        policyReorderIndex: 1,
        policySubsetListIndex: 0,
        policyLinksIndex: 0,
        includeWelcomeMess: false,
        welcomeMessProvidedByIndex: 0,
    });

    return (
        <div className={props.visible ? "visible" : "hidden"}>
            <Title>Tone of voice</Title>
            <SubTitle>Who is the main audience for this handbook?</SubTitle>
            <GridRadioDiv>
                {handbookAudiences.map((item, index) => (
                    <RadioButton
                        key={index}
                        name="handbookAudience"
                        caption={item}
                        value={index}
                        captionClassName={index === state.handbookAudienceIndex && `opacity-100 font-bold`}
                        checked={index === state.handbookAudienceIndex}
                        onChange={() => setState({ ...state, handbookAudienceIndex: index })}
                    />
                ))}
            </GridRadioDiv>

            <SubTitle marginTop={45}>What tone are you going for?</SubTitle>
            <p className="text-sm mt-2">Please choose one or select ‘other’ if it’s a combination of the two</p>
            <GridRadioDiv>
                {handbookTones.map((item, index) => (
                    <div key={index}>
                        <RadioButton
                            key={index}
                            name="handbookTone"
                            caption={item.name}
                            value={item.name}
                            captionClassName={index === state.handbookToneIndex && `opacity-100 font-bold`}
                            checked={index === state.handbookToneIndex}
                            onChange={() => setState({ ...state, handbookToneIndex: index })}
                        />
                        {index != handbookTones.length - 1 &&
                            <ExampleLink href={item.url} target="_blank">See example</ExampleLink>
                        }
                    </div>
                ))}
            </GridRadioDiv>
            {state.handbookToneIndex === 4 &&
                <TextArea
                    name="handbookToneOther"
                    placeholder="Please describe in more detail..."
                    className="h-25 mt-4" />
            }

            <SubTitle marginTop={45}>Which style would you prefer?</SubTitle>
            <GridRadioDiv columns={2}>
                {toneStyles.map((item, index) => (
                    <RadioButton
                        key={index}
                        name="toneStyle"
                        caption={item}
                        value={index}
                        captionClassName={index === state.toneStyleIndex && `opacity-100 font-bold`}
                        checked={index === state.toneStyleIndex}
                        onChange={() => setState({ ...state, toneStyleIndex: index })}
                    />
                ))}
            </GridRadioDiv>

            <Title marginTop={60}>Technical preferences</Title>
            <SubTitle>Choose your preference:</SubTitle>
            <GridRadioDiv columns={2}>
                {technicalRefs.map((item, index) => (
                    <div key={index}>
                        <RadioButton
                            name="technicalPref"
                            caption={item.name}
                            value={index}
                            captionClassName={index === state.technicalPrefIndex && `opacity-100 font-bold`}
                            checked={index === state.technicalPrefIndex}
                            onChange={() => setState({ ...state, technicalPrefIndex: index })}
                        />
                        <ExampleLink href={item.url} target="_blank">See example</ExampleLink>
                    </div>
                ))}
            </GridRadioDiv>

            <SubTitle marginTop={48}>Blurbs / summary text should be..</SubTitle>
            <GridRadioDiv columns={2}>
                {technicalBlurbs.map((item, index) => (
                    <div key={index}>
                        <RadioButton
                            name="technicalBlurbs"
                            caption={item.name}
                            value={index}
                            captionClassName={index === state.technicalBlurbsIndex && `opacity-100 font-bold`}
                            checked={index === state.technicalBlurbsIndex}
                            onChange={() => setState({ ...state, technicalBlurbsIndex: index })}
                        />
                        <ExampleLink href={item.url} target="_blank">See example</ExampleLink>
                    </div>
                ))}
            </GridRadioDiv>

            <SubTitle marginTop={48}>Summaries should be..</SubTitle>
            <GridRadioDiv columns={2}>
                {technicalSummaries.map((item, index) => (
                    <div key={index}>
                        <RadioButton
                            name="technicalSummaries"
                            caption={item.name}
                            value={index}
                            captionClassName={index === state.technicalSummariesIndex && `opacity-100 font-bold`}
                            checked={index === state.technicalSummariesIndex}
                            onChange={() => setState({ ...state, technicalSummariesIndex: index })}
                        />
                        <ExampleLink href={item.url} target="_blank">See example</ExampleLink>
                    </div>
                ))}
            </GridRadioDiv>

            <SubTitle marginTop={48}>How would you like to handle your full policies?</SubTitle>
            <GridRadioDiv columns={2}>
                {policyLinks.map((item, index) => (
                    <div key={index}>
                        <RadioButton
                            name="policyLinks"
                            caption={item.name}
                            value={index}
                            captionClassName={index === state.policyLinksIndex && `opacity-100 font-bold`}
                            checked={index === state.policyLinksIndex}
                            onChange={() => setState({ ...state, policyLinksIndex: index })}
                        />
                        <ExampleLink href={item.url} target="_blank">See example</ExampleLink>
                    </div>
                ))}
            </GridRadioDiv>

            <SubTitle marginTop={48}>In longer policies, how do you want policy subsets to be listed?</SubTitle>
            <GridRadioDiv columns={2}>
                {policySubsetLists.map((item, index) => (
                    <div key={index}>
                        <RadioButton
                            name="policySubsetList"
                            caption={item.name}
                            value={index}
                            captionClassName={index === state.policySubsetListIndex && `opacity-100 font-bold`}
                            checked={index === state.policySubsetListIndex}
                            onChange={() => setState({ ...state, policySubsetListIndex: index })}
                        />
                        <ExampleLink href={item.url} target="_blank">See example</ExampleLink>
                    </div>
                ))}
            </GridRadioDiv>

            <SubTitle marginTop={48}>Can AirMason re-order the policies?</SubTitle>
            <GridRadioDiv columns={2}>
                {reOrderPolicies.map((item, index) => (
                    <RadioButton
                        key={index}
                        name="policyReorder"
                        caption={item}
                        value={index === 0 ? true : false}
                        captionClassName={index === state.policyReorderIndex && `opacity-100 font-bold`}
                        checked={index === state.policyReorderIndex}
                        onChange={() => setState({ ...state, policyReorderIndex: index })}
                    />
                ))}
            </GridRadioDiv>

            <div className="flex mt-10 justify-between">
                <Title marginTop={1}>Would you like to include a welcome message?</Title>
                <Toggle
                    label={{ on: "Yes", off: "No" }}
                    defaultChecked={false}
                    name="includeWelcomeMess"
                    value={state.includeWelcomeMess}
                    onChange={(checked: boolean) => setState({ ...state, includeWelcomeMess: checked })}
                />
            </div>
            {state.includeWelcomeMess &&
                <div className="flex mt-6">
                    <Dot />
                    <div className="ml-4">
                        <SubTitle marginTop={1}>Welcome message provided by…</SubTitle>
                        <GridRadioDiv>
                            {welcomeMessProvidedBy.map((item, index) => (
                                <RadioButton
                                    key={index}
                                    name="welcomeMessProvidedBy"
                                    caption={item}
                                    value={index}
                                    captionClassName={index === state.welcomeMessProvidedByIndex && `opacity-100 font-bold`}
                                    checked={index === state.welcomeMessProvidedByIndex}
                                    onChange={() => setState({ ...state, welcomeMessProvidedByIndex: index })}
                                />
                            ))}
                        </GridRadioDiv>
                    </div>
                </div>
            }

            <div className="flex justify-between mt-25">
                <div
                    className="flex items-center cursor-pointer"
                    onClick={() => props.onBack()}
                >
                    <img src={backArrow} alt="back" />
                    <p className="text-black-1 text-xl ml-4">Back</p>
                </div>
                <p className="text-black-1 text-xl ml-4 mt-1.5">2 of 3</p>
                <SubmitButton
                    type="submit"
                    onClick={() => props.onNext()}>
                    Continue
                </SubmitButton>
            </div>
        </div>
    )
}

export default Form2;