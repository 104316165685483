import styled from "@emotion/styled"
import React, { forwardRef } from "react"

interface CheckboxStyledProp {
  isBlueColor: boolean
}

const CheckboxStyled = styled.div<CheckboxStyledProp>`
  line-height: 19px;

  input[type="checkbox"] {
    padding: 0;
    appearance: none;
    height: 17px !important;
    width: 17px !important;
    min-width: 17px !important;
    border: 1px solid #3d4354;
    position: relative;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
    margin: 0px 14px 0px 0px !important;

    &::before {
      content: "";
      background-image: url(${require("src/images/icons/checkbox.svg")});
      width: inherit;
      height: inherit;
      background-size: auto;
      position: absolute;
      background-repeat: no-repeat;
      text-align: center;
      background-position: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:checked {
      border-color: ${props => (props.isBlueColor ? "#1084FF" : "#44898b")};
      background-color: ${props => (props.isBlueColor ? "#1084FF" : "#44898b")};
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
      &::before {
        opacity: 1;
      }
      &:hover {
        &::before {
          background-image: url(${require("src/images/icons/checkbox.svg")});
        }
      }
    }

    &:hover {
      &::before {
        background-image: ${props =>
          props.isBlueColor
            ? `url(${require("src/images/icons/checkbox_blue.svg")})`
            : `url(${require("src/images/icons/checkbox_green.svg")})`};
        opacity: 1;
      }
    }

    &:disabled,
    &:disabled + label {
      opacity: 0.4;
      pointer-events: none;
    }
  }
`

const Checkbox: React.FC<any> = forwardRef(
  (
    {
      className,
      value,
      label = "",
      onChange,
      children,
      labelClassName,
      isBlueColor = false,
      ...props
    },
    ref
  ) => {
    return (
      <CheckboxStyled
        className={
          "flex items-center text-black-15 text-sm font-avenirBook " + className
        }
        isBlueColor={isBlueColor}
      >
        <input
          type="checkbox"
          ref={ref}
          className={"m-1 cursor-pointer"}
          onChange={(e: any) => {
            onChange && onChange(e.target.checked)
          }}
          value={value}
          checked={value}
          {...props}
        />
        {children ? (
          children
        ) : label ? (
          <label className={`${labelClassName ? labelClassName : ""}`}>
            {label}
          </label>
        ) : (
          <></>
        )}
      </CheckboxStyled>
    )
  }
)

export default Checkbox
