export const inputFieldErrors = [
    {
        name: "fullName",
        error: "Please enter your full name",
        step: 0
    },
    {
        name: "emailAddress",
        error: "Please enter your email address",
        step: 0
    },
    {
        name: "companyName",
        error: "Please enter your company name",
        step: 0
    },
    {
        name: "workersPrefOther",
        error: "Please specify workers preference",
        step: 0
    },
    {
        name: "companyRef",
        error: "Please enter company preference",
        step: 0
    },
    {
        name: "reportRefOther",
        error: "Please specify Direct Report/ Manager reference",
        step: 0
    },
    {
        name: "executiveTeamRefOther",
        error: "Please specify Executive Team reference",
        step: 0
    },
    {
        name: "hrDeptRefOther",
        error: "Please specify Human Resources reference",
        step: 0
    },
    {
        name: "disklikesOrPrefs",
        error: "Please specify Dislikes or preferences",
        step: 0
    },
    {
        name: "commonTerminologyPhrases",
        error: "Please specify Common company terminology or phrases",
        step: 0
    },
    {
        name: "particularPhrasesToAvoid",
        error: "Please specify Words or phrases to avoid",
        step: 0
    },
    {
        name: "handbookToneOther",
        error: "Please describle What tone you are going for",
        step: 1
    },
    {
        name: "additionalCultureAdjectives",
        error: "Please specify additional culture adjectives",
        step: 2
    },
    {
        name: "employeeMostProud",
        error: "Please specify what employees feel most proud about working at your company",
        step: 2
    },
    {
        name: "otherCulturePages",
        error: "Please specify other culture pages",
        step: 2
    }
];

export const otherFields = [
    {
        name: "workersPrefOther",
        origin: "workersPref"
    },
    {
        name: "reportRefOther",
        origin: "reportRef"
    },
    {
        name: "executiveTeamRefOther",
        origin: "executiveTeamRef"
    },
    {
        name: "hrDeptRefOther",
        origin: "hrDeptRef"
    },
    {
        name: "handbookToneOther",
        origin: "handbookTone"
    }
];