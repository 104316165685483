import React from "react"

interface AboutProps {
  title: string
  description: string[]
  showBackButton: boolean
}

const About: React.FC<AboutProps> = ({
  title,
  description,
  showBackButton,
}) => {
  return (
    <div className={`sm:pt-155px pt-110px px-27px bg-gray-2`} id="about">
      <div className="max-w-820px mx-auto md:ml-175px">
        <div className="flex-1 flex flex-col text-green-4">
          <h1 className="sm:max-w-586px max-w-326px font-bold sm:text-52px text-30px sm:leading-62px leading-36px">
            {title}
          </h1>
          <div
            className={`max-w-255px sm:max-w-full text-green-4 text-14px sm:text-base sm:leading-6 leading-21px pt-17px ${
              showBackButton ? "pb-8" : "sm:pb-155px pb-6"
            }`}
          >
            {description.map((desc, idx) => {
              return <div key={idx}>{desc}</div>
            })}
          </div>
          {showBackButton && (
            <a
              className="py-2 px-4 bg-blue-1 rounded-full text-white text-15px no-underline w-fit mb-10"
              href="/"
            >
              Return to AirMason
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default About
