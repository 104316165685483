import React from "react"
import { StepBarCircle, StepBarTitle } from "./styles"

type StepBarProps = {
  activeTab: number
  completedIndex: number
}

const tabNames = [
  "Terminology",
  "Tone & Technicals",
  "Culture",
]

const StepBar: React.FC<StepBarProps> = ({ activeTab, completedIndex }) => {
  return (
    <div className="bg-white rounded-lg py-9 px-12 my-6 flex justify-between border-gray-5 border mx-auto md:ml-175px max-w-lg">
      {tabNames.map((name, index) => (
        <div className="flex flex-col justify-center items-center" key={index}>
          <StepBarCircle
            active={activeTab === index}
            completed={index < completedIndex}
          />
          <StepBarTitle active={activeTab === index}>
            {index + 1}. {name}
          </StepBarTitle>
        </div>
      ))}
    </div>
  )
}

export default StepBar
